import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from '@/lang'
import Cookies from 'js-cookie'
import ElementUI from 'element-ui';
import VueClipboard from 'vue-clipboard2'//复制
import 'element-ui/lib/theme-chalk/index.css';
import '@/common/css/lxh.scss'
import 'animate.css'
Vue.use(ElementUI);
Vue.use(VueClipboard)
Vue.prototype.$cookies = Cookies
Vue.prototype.$notify = ElementUI.Notification 
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
