import Vue from 'vue'
import VueRouter from 'vue-router'
import tabbar from '@/App.vue'
Vue.use(VueRouter)
VueRouter.selfaddRoutes = function (params){
  VueRouter.matcher = new VueRouter().matcher;
  VueRouter.addRoutes(params)
}
const router = new VueRouter({
  mode: 'hash',
  // 'history':'hash'
  routes: [
    {
      path: '/',
      name: 'tabbar',
      component: tabbar, // 主体
      children: [ {
        path: '/',
        name: 'index',
        component: () => import('@/views/shou')// 首页
      },]
    },
   
  ]
})
export default router
