<template>
  <div id="app">
    <div class="box_body">
     <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {

  },
  components: {},
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
.box_body {
  width: 100vw;
  min-height: 100vh;
  background: #000000;
  overflow-x: hidden;
  color: rgba(204, 204, 204, 1);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: 100% !important;
  -moz-osx-font-smoothing: 100% !important;
  -webkit-text-size-adjust: 100% !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
